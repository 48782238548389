
import Vue from 'vue';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';

import theme from 'Theme';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import { deleteAddress, editAddress, getAddresses, getPendingAddresses } from 'Store/v2/Addresses';
import BlockHeader from 'UI/BlockHeader.vue';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import TextInput from 'Control/TextInput.vue';
import PublicDataApi from 'Apis/PublicData';
import AssetsRequest from 'Entities/publicPresenter/AssetsRequest';
import BlockchainsRequest from 'Entities/publicPresenter/BlockchainsRequest';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import ApiError from 'Entities/ApiError';
import Dropdown from 'Control/Dropdown.vue';
import Blockchain from 'Entities/publicPresenter/Blockchain';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import HeaderSwitcher from 'Control/HeaderSwitcher.vue';
import NoData from 'UI/NoData.vue';
import BankRequisitesResponseData, {
    IBankRequisitesResponseData,
} from 'Entities/walletExecutor/BankRequisitesResponseData';
import WalletsApi from 'Apis/Wallets';
import CountryInput from 'Control/CountryInput.vue';
import CreateBankRequisitesRequestData, {
    ICreateBankRequisitesRequestData,
} from 'Entities/walletExecutor/CreateBankRequisitesRequestData';
import DeleteRequisitesRequestData from 'Entities/walletExecutor/DeleteRequisitesRequestData';
import { MFA_ENROLL_FACTOR_TYPES } from 'Config/auth';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import Asset, { IAsset } from 'Entities/publicPresenter/Asset';
import { deepEqual } from 'Lib/utils/DeepEqual';

interface Data {
    theme: typeof theme;
    editedAddressIndex: null | number;
    editNameValue: string;
    searchValue: string;
    isSearchActive: boolean;
    errors: { editNameValue: boolean; };
    selectedBlockchainIndex: number;
    blockchainsList: Blockchain[];
    tableVariant: number;
    requisites: BankRequisitesResponseData[];
    editingRequisite: IBankRequisitesResponseData;
    startEditingRequisiteValue: IBankRequisitesResponseData;
    editingRequisiteErrors: Record<string, boolean>;
    activeBeneficiaryNameIndex: number;
}

interface Methods {
    getShortAddress: (data: string) => string;
    copy: (data: string) => void;
    deleteAddress: (data: UserTransferAddress) => void;
    onSearch: (data: string) => void;
    setEdit: (data: number) => void;
    cancelEdit: () => void;
    confirmEdit: (data: number) => void;
    openWhitelisting: () => void;
    openRequisitesWhitelisting: () => void;
    loadData: () => void;
    getBlockchains: () => void;
    selectBlockchain: (data: { index: number }) => void;
    deleteRequisite: (data: BankRequisitesResponseData) => Promise<void>;
    loadRequisitesData: () => void;
    editRequisite: (data: BankRequisitesResponseData) => void;
    saveRequisite: () => void;
    beforeCloseModal: () => void;
    goBack: () => void;
    openLink: (data: string) => void;
}

interface Computed {
    addresses: UserTransferAddress[];
    pendingAddresses: UserTransferAddress[];
    searchedAddresses: UserTransferAddress[];
    searchedPendingAddresses: UserTransferAddress[];
    filteredAddresses: UserTransferAddress[];
    filteredPendingAddresses: UserTransferAddress[];
    currentUser: InternalUserResponse | null;
    validBeneficiaryNames: string[];
    isSaveRequisiteButtonDisabled: boolean;
}

export default Vue.extend<Data, Methods, Computed>({
    components: {
        AssetsBlockchainsDropdown,
        CountryInput,
        NoData,
        HeaderSwitcher,
        Dropdown,
        BlockHeader,
        Icon,
        Button,
        TextInput,
    },
    data() {
        return {
            theme,
            editedAddressIndex: null,
            editNameValue: '',
            searchValue: '',
            isSearchActive: false,
            tableVariant: 0,
            requisites: [],
            activeBeneficiaryNameIndex: 0,
            errors: {
                editNameValue: false,
            },
            editingRequisiteErrors: {
                beneficiaryAddress: false,
                beneficiaryBankAddress: false,
                beneficiaryBankCity: false,
                beneficiaryBankName: false,
                beneficiaryBankPostalCode: false,
                beneficiaryCity: false,
                beneficiaryIban: false,
                beneficiaryName: false,
                beneficiaryPostalCode: false,
                beneficiarySwift: false,
                beneficiaryAccountNumber: false,
            },
            selectedBlockchainIndex: 0,
            blockchainsList: [],
            editingRequisite: {},
            startEditingRequisiteValue: {},
        };
    },
    validations() {
        return {
            editNameValue: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(20),
            },
            editingRequisite: {
                beneficiaryAddress: {
                    required,
                },
                beneficiaryBankAddress: {
                    required,
                },
                beneficiaryBankCity: {
                    required,
                },
                beneficiaryBankName: {
                    required,
                },
                beneficiaryBankPostalCode: {
                    required,
                },
                beneficiaryCity: {
                    required,
                },
                beneficiaryIban: {
                    required,
                },
                beneficiaryName: {
                    required,
                },
                beneficiaryPostalCode: {
                    required,
                },
                beneficiarySwift: {
                    required,
                },
                beneficiaryAccountNumber: {
                    required,
                },
            },
        };
    },
    computed: {
        isSaveRequisiteButtonDisabled() {
            return deepEqual(this.editingRequisite, this.startEditingRequisiteValue);
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        addresses() {
            return this.$store.state.Addresses.addresses;
        },
        pendingAddresses() {
            return this.$store.state.Addresses.pendingAddresses;
        },
        filteredAddresses() {
            if (this.selectedBlockchainIndex === 0) {
                return this.addresses;
            }
            return this.addresses
                .filter(({ blockchainName }) => blockchainName === this.blockchainsList[this.selectedBlockchainIndex - 1].name);
        },
        filteredPendingAddresses() {
            if (this.selectedBlockchainIndex === 0) {
                return this.pendingAddresses;
            }
            return this.pendingAddresses
                .filter(({ blockchainName }) => blockchainName === this.blockchainsList[this.selectedBlockchainIndex - 1].name);
        },
        searchedAddresses() {
            return this.isSearchActive
                ? this.filteredAddresses.filter(({ alias }) => alias.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1)
                : this.filteredAddresses;
        },
        searchedPendingAddresses() {
            return this.isSearchActive
                ? this.filteredPendingAddresses.filter(({ alias }) => alias.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1)
                : this.filteredPendingAddresses;
        },
        validBeneficiaryNames() {
            return this.currentUser?.possibleNames ?? [];
        },
    },
    methods: {
        openLink(link) {
            window.open(link);
        },
        goBack() {
            const { lastRoute } = this.$store.state.Addresses;
            if (lastRoute) {
                this.$router.push(lastRoute);
            } else {
                this.$router.push('/wallets');
            }
        },
        async beforeCloseModal() {
            this.$v.$reset();
            this.editingRequisite = {};
            this.startEditingRequisiteValue = {};
            Object.keys(this.editingRequisiteErrors).forEach((k) => {
                this.editingRequisiteErrors[k] = false;
            });
            await this.loadRequisitesData();
        },
        async saveRequisite() {
            this.$v.$touch();
            if (!this.$v.editingRequisite.$invalid) {
                try {
                    const { emailCode, totpCode } = await this.$store.dispatch(
                        'Auth/getMFAToken',
                        {
                            type: MFA_ENROLL_FACTOR_TYPES.EMAIL_TOTP,
                            action: 'SaveBankRequisites',
                            emailTemplateData: {
                                recipientName: this.validBeneficiaryNames[this.activeBeneficiaryNameIndex],
                                bankAccountNumber: this.editingRequisite.beneficiaryAccountNumber,
                            },
                        },
                    );
                    this.$store.commit(SET_LOADING_ON(undefined));
                    await WalletsApi.saveUserBankRequisites(new CreateBankRequisitesRequestData({
                        ...(this.editingRequisite as ICreateBankRequisitesRequestData),
                        emailTotp: emailCode,
                        totp: totpCode,
                    }));
                    await this.$store.dispatch('Notificator/showSuccessNotification', 'Bank account has been successfully updated');
                    this.$modal.hide('editRequisiteModal');
                    await this.$router.push('/profile/addresses-management?variant=1');
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error editing bank account');
                        await this.saveRequisite();
                    }
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            } else {
                if (this.$v.editingRequisite.beneficiaryAddress.$invalid) {
                    this.editingRequisiteErrors.beneficiaryAddress = true;
                }
                if (this.$v.editingRequisite.beneficiaryBankAddress.$invalid) {
                    this.editingRequisiteErrors.beneficiaryBankAddress = true;
                }
                if (this.$v.editingRequisite.beneficiaryBankCity.$invalid) {
                    this.editingRequisiteErrors.beneficiaryBankCity = true;
                }
                if (this.$v.editingRequisite.beneficiaryBankName.$invalid) {
                    this.editingRequisiteErrors.beneficiaryBankName = true;
                }
                if (this.$v.editingRequisite.beneficiaryBankPostalCode.$invalid) {
                    this.editingRequisiteErrors.beneficiaryBankPostalCode = true;
                }
                if (this.$v.editingRequisite.beneficiaryCity.$invalid) {
                    this.editingRequisiteErrors.beneficiaryCity = true;
                }
                if (this.$v.editingRequisite.beneficiaryIban.$invalid) {
                    this.editingRequisiteErrors.beneficiaryIban = true;
                }
                if (this.$v.editingRequisite.beneficiaryName.$invalid) {
                    this.editingRequisiteErrors.beneficiaryName = true;
                }
                if (this.$v.editingRequisite.beneficiaryPostalCode.$invalid) {
                    this.editingRequisiteErrors.beneficiaryPostalCode = true;
                }
                if (this.$v.editingRequisite.beneficiarySwift.$invalid) {
                    this.editingRequisiteErrors.beneficiarySwift = true;
                }
                if (this.$v.editingRequisite.beneficiaryAccountNumber.$invalid) {
                    this.editingRequisiteErrors.beneficiaryAccountNumber = true;
                }
            }
        },
        editRequisite(requisite) {
            this.editingRequisite = { ...requisite.serialize() };
            this.startEditingRequisiteValue = { ...requisite.serialize() };
            const index = this.validBeneficiaryNames.findIndex((el) => el === this.editingRequisite.beneficiaryName);
            this.activeBeneficiaryNameIndex = (index !== -1 ? index : 0);
            this.$modal.show('editRequisiteModal');
        },
        async deleteRequisite(requisite: BankRequisitesResponseData) {
            const previousRoute = this.$router.currentRoute.path;
            try {
                const totp = await this.$store.dispatch(
                    'Auth/getMFAToken',
                    {
                        type: MFA_ENROLL_FACTOR_TYPES.TOTP,
                        previousRoute,
                    },
                );
                this.$store.commit(SET_LOADING_ON(undefined));
                await WalletsApi.deleteUserBankRequisites(new DeleteRequisitesRequestData({
                    alias: requisite.alias ?? '',
                    totp,
                }));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Requisite has been successfully deleted');
                await this.loadRequisitesData();
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error deleting requisite');
                }
            } finally {
                await this.$router.push(previousRoute);
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async openRequisitesWhitelisting() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                const { data: assets } = await PublicDataApi.publicGetAssets(new AssetsRequest({
                    transferable: true,
                    includeTransferDetails: true,
                    perPage: 256,
                    fromPlacementName: 'Single Broker',
                }));
                await this.$router.push({
                    path: '/Wallets/Confirmations/requisites-whitelisting',
                    query: {
                        previousRoute: this.$router.currentRoute.path,
                        variant: '1',
                        assets: JSON.stringify(assets.reduce((accum, current) => {
                            if (current.type === 'fiat') {
                                accum.push((current as Asset).serialize());
                            }
                            return accum;
                        }, [] as IAsset[])),
                    },
                });
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async loadRequisitesData() {
            const { data: requisites } = await WalletsApi.listUserBankRequisites();
            this.requisites = requisites.filter(({ asset }) => asset);
        },
        selectBlockchain(event) {
            this.selectedBlockchainIndex = event.index;
        },
        getShortAddress(address) {
            if (address.length < 9) {
                return address;
            }
            return `${address.substring(0, 4)}...${address.substring(address.length - 4, address.length)}`;
        },
        copy(value) {
            copyTextWithPermissions(value, this.$store.dispatch, 'Address');
        },
        async deleteAddress({ alias, blockchainName, address }) {
            await this.$store.dispatch(deleteAddress({ alias, blockchainName, address }));
            await this.loadData();
        },
        onSearch(value) {
            this.searchValue = value;
        },
        setEdit(index) {
            if (index < this.searchedAddresses.length) {
                this.editNameValue = this.searchedAddresses[index].alias;
            } else {
                this.editNameValue = this.searchedPendingAddresses[index - this.searchedAddresses.length].alias;
            }
            this.editedAddressIndex = index;
        },
        cancelEdit() {
            this.editedAddressIndex = null;
            this.editNameValue = '';
        },
        async confirmEdit(index) {
            try {
                this.editNameValue = this.editNameValue.trim();
                this.$v.$touch();
                if (!this.$v.editNameValue.$invalid) {
                    if (index < this.searchedAddresses.length) {
                        await this.$store.dispatch(editAddress({
                            newAlias: this.editNameValue,
                            alias: this.searchedAddresses[index].alias,
                            blockchainName: this.searchedAddresses[index].blockchainName,
                            address: this.searchedAddresses[index].address,
                        }));
                    } else {
                        index -= this.searchedAddresses.length;
                        await this.$store.dispatch(editAddress({
                            newAlias: this.editNameValue,
                            alias: this.searchedPendingAddresses[index].alias,
                            blockchainName: this.searchedPendingAddresses[index].blockchainName,
                            address: this.searchedPendingAddresses[index].address,
                        }));
                    }
                    this.$v.$reset();
                } else {
                    this.errors.editNameValue = true;
                }
            } finally {
                if (!this.errors.editNameValue) {
                    this.editedAddressIndex = null;
                    this.editNameValue = '';
                }
                await this.loadData();
            }
        },
        async openWhitelisting() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                const { data: assets } = await PublicDataApi.publicGetAssets(new AssetsRequest({
                    transferable: true,
                    includeTransferDetails: true,
                    perPage: 256,
                    fromPlacementName: 'Single Broker',
                }));
                const { data: blockchains } = await PublicDataApi.publicGetBlockchains(new BlockchainsRequest({
                    perPage: 256,
                    placementName: 'Single Broker',
                }));
                await this.$router.push({
                    path: '/Wallets/Confirmations/Whitelisting',
                    query: {
                        assets: assets.map((a) => a.serialize()) as any,
                        allBlockchains: blockchains.map((b) => b.serialize()) as any,
                        previousRoute: this.$router.currentRoute.path,
                    },
                });
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error preparing for whitelisting');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        async loadData() {
            await this.$store.dispatch(getAddresses(undefined));
            await this.$store.dispatch(getPendingAddresses(undefined));
        },
        async getBlockchains() {
            try {
                let allBlockchains: Blockchain[] = [];
                const { data: blockchains, headers } = await PublicDataApi.publicGetBlockchains(new BlockchainsRequest({
                    placementName: 'Single Broker',
                    includeTotal: true,
                    page: 1,
                    perPage: 100,
                }), true);
                allBlockchains = blockchains;
                if (headers) {
                    const { totalPage } = parsePaginationHeaders(headers);
                    if (totalPage && totalPage > 1) {
                        for (let i = 2; i <= totalPage; i += 1) {
                            // eslint-disable-next-line no-await-in-loop
                            const { data: extraBlockchains } = await PublicDataApi.publicGetBlockchains(new BlockchainsRequest({
                                placementName: 'Single Broker',
                                page: i,
                                perPage: 100,
                            }));
                            allBlockchains = [...allBlockchains, ...extraBlockchains];
                        }
                    }
                }
                this.blockchainsList = allBlockchains;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data?.message ?? 'Error getting blockchains list');
                }
                this.blockchainsList = [];
            }
        },
    },
    async mounted() {
        const { variant } = this.$route.query;
        if (variant && variant === '1') {
            this.tableVariant = 1;
        }

        await this.loadData();
        await this.loadRequisitesData();
        await this.getBlockchains();
        await this.$store.dispatch('VuexEventListener/addActionListener', {
            type: 'UiActions/needUpdateAddresses',
            callback: async () => {
                await this.loadData();
            },
        });
    },
    watch: {
        searchValue(value) {
            this.isSearchActive = value.length > 0;
        },
        tableVariant(val) {
            this.$router.replace({ query: { variant: val } });
        },
        activeBeneficiaryNameIndex(index) {
            this.editingRequisite.beneficiaryName = this.validBeneficiaryNames[index];
        },
    },
});
